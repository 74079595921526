import React from 'react'
import { Container, Typography, Box, Stack, Link } from '@mui/material'
import Footer from './Footer'

export default function FAQPage() {
    return (
        <>
            <Container maxWidth='sm'>
                <Box p={5}></Box>
                <Stack spacing={5}>
                <Typography textAlign='center' variant="h3" gutterBottom>
                    <strong>Preguntas Frecuentes</strong>
                </Typography>
                <Typography variant="h6" gutterBottom>
                    ¿Por qué Polivoz necesita mi correo electrónico?
                </Typography>
                <Typography paragraph>
                    Usamos su correo electrónico para configurar y validar su cuenta de usuario y para evitar el abuso de la plataforma. Cualquier persona que viole nuestros Términos y Condiciones de uso o nuestra Política de Privacidad, o participe en conductas como acoso, discriminación o conducta ilegal puede ser excluida de Polivoz.
                </Typography>
                <Typography paragraph>
                    Para obtener más información sobre cómo usamos y protegemos sus datos, consulte nuestra{' '}
                    <Link href="/policy" color="secondary">
                        Política de Privacidad
                    </Link>.
                </Typography>

                <Typography variant="h6" gutterBottom>
                    ¿Cómo verifico mi cuenta?
                </Typography>
                <Typography paragraph>
                    Para verificar su cuenta en Polivoz, asegúrese de tener la última versión de Polivoz y luego vaya a su Perfil {'>'} Verificar cuenta.
                </Typography>

                <Typography variant="h6" gutterBottom>
                    ¿Qué es la Verificación con video y voz?
                </Typography>
                <Typography paragraph>
                    La Verificación con video y voz es un proceso opcional. Después de una Verificación con video y voz exitosa, obtendrá una insignia en su perfil, para mostrarles a las personas que ha completado el proceso de Verificación y además tendrá acceso a participar en la rifa de DOGE que se realiza cada mes.
                </Typography>
                <Typography paragraph>
                    Con la Verificación, también podrá ver si otros perfiles de Polivoz han completado el proceso de Verificación. De esa manera, podrá tomar decisiones más informadas sobre con quién interactúa en Polivoz.
                </Typography>

                <Typography variant="h6" gutterBottom>
                    ¿Cómo se utiliza la información de mi Verificación?
                </Typography>
                <Typography paragraph>
                    Sus datos de geometría facial y voz solo se utilizan para fines de la Verificación. Su video “selfie” no se almacena, y se elimina inmediatamente después de completar la Verificación. Las tres capturas de pantalla que tomamos de su video “selfie” se utilizan únicamente con el propósito de auditar y administrar esta función.
                </Typography>
                <Typography paragraph>
                    Para realizar el proceso de Verificación, almacenamos y utilizamos sus datos de geometría facial y capturas de pantalla en nuestras instancias de Google Cloud. Esta información y capturas de pantalla se eliminan de nuestras instancias de Google Cloud en los plazos que se detallan en ¿Qué información guardarán después de la Verificación?
                </Typography>
                {/* Continúa añadiendo más secciones de texto para cada pregunta */}
                <Typography variant="h6" gutterBottom>
                    ¿Por qué necesito habilitar la ubicación y las notificaciones?
                </Typography>
                <Typography paragraph>
                    La misión de Polivoz es hacer de su país un lugar más seguro. Le pedimos que habilite sus permisos de ubicación y notificaciones para que Polivoz pueda trabajar en segundo plano, incluso cuando no esté usando la aplicación, para enviarle alertas en tiempo real que, a veces, pueden salvarle la vida.
                </Typography>
                <Typography paragraph>
                    Para obtener ayuda para solucionar problemas con estas configuraciones, consulte{' '}
                    <Link href="/policy" color="secondary">
                        ¿Cómo habilito las notificaciones y la ubicación?
                    </Link>.
                </Typography>
                </Stack>
            </Container>
            <Footer />
        </>
    )
}