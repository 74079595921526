import React from 'react'
import { Container, Box, Typography, Stack } from '@mui/material'
import Footer from './Footer'
export default function PolicyPage() {
    return (
        <>
            <Container maxWidth='sm'>
                <Box p={5}></Box>
                <Stack spacing={5}>
                    <Typography textAlign='center' variant="h3">
                        <strong>Política de privacidad</strong>
                    </Typography>
                    <Typography>Esta Política de privacidad describe cómo se recopila, utiliza y comparte su información personal cuando visita o hace una compra en polivoz.com
                        (denominado en lo sucesivo el “Sitio”).
                    </Typography>
                    <Typography>Cuando visita el Sitio, recopilamos automáticamente cierta información sobre su dispositivo, incluida información sobre su navegador web, dirección Izona horaria y algunas de las cookies que están instaladas en su dispositivo. Además, a medida que navega por el Sitio, recopilamos información sobrelas páginas web individuales o los productos que ve, las páginas web o los términos de búsqueda que lo remitieron al Sitio e información sobre cómo
                        interactúa usted con el Sitio. Nos referimos a esta información recopilada automáticamente como “Información del dispositivo”.
                    </Typography>
                    <Typography>
                        Recopilamos Información del dispositivo mediante el uso de las siguientes tecnologías:
                    </Typography>
                    <Typography>
                        Cuando hablamos de “Información personal” en la presente Política de privacidad, nos referimos tanto a la Información del dispositivo como a la
                        Información del pedido.

                    </Typography>
                    <Typography>
                        ¿CÓMO UTILIZAMOS SU INFORMACIÓN PERSONAL?

                    </Typography>
                    <Typography>
                        Usamos la Información del pedido que recopilamos en general para preparar los pedidos realizados a través del Sitio (incluido el procesamiento de suinformación de pago, la organización de los envíos y la entrega de facturas y/o confirmaciones de pedido). Además, utilizamos esta Información del
                        pedido para: comunicarnos con usted;

                    </Typography>
                    <Typography>
                        examinar nuestros pedidos en busca de fraudes o riesgos potenciales; y cuando de acuerdo con las preferencias que usted compartió con nosotros, leproporcionamos información o publicidad relacionada con nuestros productos o servicios.

                    </Typography>
                    <Typography>
                        Utilizamos la Información del dispositivo que recopilamos para ayudarnos a detectar posibles riesgos y fraudes (en particular, su dirección IP) y, en
                        general, para mejorar y optimizar nuestro Sitio (por ejemplo, al generar informes y estadísticas sobre cómo nuestros clientes navegan e interactúan co
                        general, para mejorar y optimizar nuestro Sitio (por ejemplo, al generar informes y estadísticas sobre cómo nuestros clientes navegan e interactúan coel Sitio y para evaluar el éxito de nuestras campañas publicitarias y de marketing).
                    </Typography>
                    <Typography>
                        COMPARTIR SU INFORMACIÓN PERSONAL

                    </Typography>
                    <Typography>
                        Compartimos su Información personal con terceros para que nos ayuden a utilizar su Información personal, tal como se describió anteriormente. Porejemplo, utilizamos la tecnología de Shopify en nuestra tienda online. Puede obtener más información sobre cómo Shopify utiliza su Información
                        personal aquí: https://www.shopify.com/legal/privacy. También utilizamos Google Analytics para ayudarnos a comprender cómo usan nuestros clienteel Sitio. Puede obtener más información sobre cómo Google utiliza su Información personal aquí: https://www.google.com/intl/es/policies/privacy/.
                        Puede darse de baja de Google Analytics aquí: https://tools.google.com/dlpage/gaoptout.

                    </Typography>
                    <Typography>
                        Finalmente, también podemos compartir su Información personal para cumplir con las leyes y regulaciones aplicables, para responder a una citación,
                        orden de registro u otra solicitud legal de información que recibamos, o para proteger nuestros derechos.
                    </Typography>
                    <Typography>
                        PUBLICIDAD ORIENTADA POR EL COMPORTAMIENTO
                    </Typography>
                    <Typography>
                        NO RASTREAR
                    </Typography>
                    <Typography>
                        Tenga en cuenta que no alteramos las prácticas de recopilación y uso de datos de nuestro Sitio cuando vemos una señal de No rastrear desde su
                        navegador.

                    </Typography>
                    <Typography>
                        Si usted es un residente europeo, tiene derecho a acceder a la información personal que tenemos sobre usted y a solicitar que su información personasea corregida, actualizada o eliminada. Si desea ejercer este derecho, comuníquese con nosotros a través de la información de contacto que se
                        encuentra a continuación.

                    </Typography>
                    <Typography>
                        RETENCIÓN DE DATOS


                    </Typography>
                    <Typography>
                        Cuando realiza un pedido a través del Sitio, mantendremos su Información del pedido para nuestros registros a menos que y hasta que nos pida que
                        eliminemos esta información.
                    </Typography>
                    <Typography>
                        MENORES
                        El sitio no está destinado a personas menores de 18.

                    </Typography>
                    <Typography>
                        CONTÁCTENOS
                    </Typography>
                    <Typography>
                        Para obtener más información sobre nuestras prácticas de privacidad, si tiene alguna pregunta o si desea presentar una queja, contáctenos por correoelectrónico a soporte@polivoz.com
                    </Typography>
                </Stack>
            </Container>
            <Footer />
        </>
    )
}