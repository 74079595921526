import React from 'react'
import { Container, Box, Typography, Stack } from '@mui/material'
import Footer from '../Footer'
export default function DeleteAccount() {
    return (
        <Container>
            <Box p={5}></Box>
            <Stack spacing={5}>
                <Typography variant="h3">
                    <strong>Eliminación de cuenta</strong>
                </Typography>
                <Typography>Para eliminar tu cuenta rellena el formulario de solicitud de eliminación de cuenta, validaremos la información y recibirás un correo electrónico de confirmación. Si no recibes el correo electrónico, pónte en contacto con nosotros a través de
                    soporte@polivoz.com
                </Typography>
                <Typography>Al eliminar tu cuenta de Polivoz, perderás para siempre el acceso a toda tu información y esto no se puede deshacer. También se cerrará tu sesión en la aplicación.
                </Typography>
            </Stack>
            <Footer />
        </Container>
    )
}